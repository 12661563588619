import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import {BOGS_ACCESS_TOKEN} from "../contants/token";

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
    const [games, setGames] = useState([]);

    const fetchGames = async () => {
        try {
            if (!localStorage.getItem(BOGS_ACCESS_TOKEN)) { window.location.href('/login')}
            const res = await axiosInstance.get('/game');
            setGames(res.data.data);
        } catch (error) {
            console.error(`Error fetching games: ${JSON.stringify(error)}`);
        }
    };

    useEffect(() => {
        fetchGames();
    }, []);

    return (
        <GameContext.Provider value={{ games }}>
            {children}
        </GameContext.Provider>
    );
};
